// Import Handlebars runtime lib
import Handlebars from 'handlebars';

Handlebars.registerHelper('toLowerCase', function(str) {
    return str.toLowerCase();
})


// Returns us a link
// Checks asset -> page -> url
// If all empty return '#'
// usage. {{#showpadLink obj.showpadLink}}
Handlebars.registerHelper('showpadLink', function(obj) {
    if( obj ) {
        // Object found
        if( obj.asset.hasOwnProperty("_result") && obj.asset._result.length > 0 ) {
            return obj.asset._result["0"].applink + "?modal=1"
        } else if( obj.page.hasOwnProperty("_result") && obj.page._result.length > 0 ) {
            return "showpad://file/"+ obj.page._result["0"].applink + "?modal=1"
        } else if( obj.url.hasOwnProperty("_result") && obj.url._result != "" && obj.url._result != null ) {
            return obj.url._result
        } else {
            return "#"
        }
    } else {
        // No object found
        return "#"
    }
})

// Returns us the path to an image
// If empty, use the image path used in the option parameter
// ex. {{#showpadImage image}}./assets/img/placeholder.jpg{{/showpadImage}}
Handlebars.registerHelper('showpadImage', function(obj, options) {
    if( !obj ) return options.fn(this)
    if( !obj["result"] ) return options.fn(this)
    if( !obj["result"][0] ) return options.fn(this)

    var img = obj["_result"][0]
    return ( img.file_url ) ? img.file_url : options.fn(this)
})

Handlebars.registerHelper('getAssetExtension', function(str) {
    if( str ) {
        var extension = str.split(".").pop()
        return extension
    } else {
        return null
    }
})

Handlebars.registerHelper('maxChar', function(str, length) {
    if( str.length > length ) {
        str = str.substring(0, length) + "..."
    }
    return str
})

Handlebars.registerHelper('compare', function (l, sign, r, options) {
    var signs, result

    if (arguments.length < 3) {
        throw new Error('Handlerbars Helper \'compare\' needs 2 parameters')
    }

    if (options === undefined) {
        options = r
        r = sign
        sign = '==='
    }

    signs = {
        '==': function (l, r) {
            return l == r
        },
        '===': function (l, r) {
            return l === r
        },
        '!=': function (l, r) {
            return l != r
        },
        '!==': function (l, r) {
            return l !== r
        },
        '<': function (l, r) {
            return l < r
        },
        '>': function (l, r) {
            return l > r
        },
        '<=': function (l, r) {
            return l <= r
        },
        '>=': function (l, r) {
            return l >= r
        },
        'typeof': function (l, r) {
            return typeof l === r
        },
            '||': function (l, r) {
        return l || r
        },
            '&&': function (l, r) {
        return l && r
        }
    }

    if (!signs[sign]) {
        throw new Error('Handlerbars Helper \'compare\' doesn\'t know the sign ' + sign)
    }

    result = signs[sign](l, r)

    if (result) {
        return options.fn(this)
    } else {
        return options.inverse(this)
    }
})

Handlebars.registerHelper('notEmpty', function (obj=null) {
    if( obj === null ||
        obj === undefined ||
        obj === "" ) return false

    return true
})

Handlebars.registerHelper('json', function(obj) {
    console.error(obj)
})

Handlebars.registerHelper("concat", function(arg1, arg2) {
    return (arg1 + "" + arg2)
})

Handlebars.registerHelper('len', function (obj) {
    return general.objectSize(obj);
})

Handlebars.registerHelper('sortObj', function (obj, prop) {

    var arr = Object.entries(obj).map((e) => ( e[1] ));

    // sort by name
    arr.sort(function(a, b) {
        var elA = Object.byString(a, prop).toUpperCase(); // ignore upper and lowercase
        var elB = Object.byString(b, prop).toUpperCase(); // ignore upper and lowercase
        if (elA < elB) {
        return -1;
        }
        if (elA > elB) {
        return 1;
        }

        // names must be equal
        return 0;
    });

    return arr
})

Handlebars.registerHelper('readableDate', function (date) {
    var d = new Date(date)

    var day = d.getDate();
    var month = d.getMonth() + 1; // getMonth() returns month from 0 to 11
    var year = d.getFullYear();

    const str = `${day}/${month}/${year}`;

    return str
})

// Lets us use a partial with our needed variables as parameters
// ex. {{{partial "title" obj=(lookup obj "title")}}}
Handlebars.registerHelper('partial', function(name, ctx, hash) {
    var ps = Handlebars.partials;
    if(typeof ps[name] !== 'function')
        ps[name] = Handlebars.compile(ps[name]);
    return ps[name](ctx, hash);
})

// Returns a nested object by using an obj and a string path
Handlebars.registerHelper('ObjectByString', function(o, s) {
    return Object.byString(o, s)
})

// Usage
// {{#for 0 10 2}}
//     <span>{{this}}</span>
// {{/for}}
Handlebars.registerHelper('for', function(from, to, incr, block) {
    var accum = '';
    for(var i = from; i < to; i += incr)
        accum += block.fn(i);
    return accum;
});

// Lets us declare a variable inside a template, so we don't check the same thing twice
// ex. {{setVar "name" "val"}}
// use variable {{@root.name}}
Handlebars.registerHelper("setVar", function(name, val, options) {
  options.data.root[name] = val;
})

// ex. {{setCustomVar @root.hash.name (concat " " obj.value) @root.hash.obj}}
// use variable {{@root.hash.obj[@root.hash.name]}}
Handlebars.registerHelper("setCustomVar", function(name, val, obj) {
  obj[name] = val;
})

export default Handlebars
