import _ from 'lodash'; // More info: https://lodash.com/docs/4.17.15#get


var string = {

    init: function () {
    },

    toLower: function (string) {

        return _.toLower(string);
    },

    trim: function (string) {

        return _.trim(string);
    },

    replace: function (string, pattern, replacement) {

        return _.replace(string, pattern, replacement);
    },

    split: function (string, separator, limit = 1000) {

        return _.split(string, separator, limit);
    },

    stripHTMLTags: function (html) {
        return html.replace(/<[^>]*>/g, '');
    }
}

window.string = string
export default string


