import _ from 'lodash'; // More info: https://lodash.com/docs/4.17.15#get


var object = {

    init: function () {
    },

    get: function (obj, path) {

        return _.get(obj, path);
    },

    set: function (obj, path, value) {

        return _.set(obj, path, value);
    },

    unset: function (obj, keys) {

        return _.unset(obj, keys);
    },

    has: function (obj, key) {

        return _.has(obj, key);
    },

    filter: function (obj, parms) {

        /*
            var users = [
              { 'user': 'barney', 'age': 36, 'active': true },
              { 'user': 'fred',   'age': 40, 'active': false }
            ];

            _.filter(users, { 'age': 36, 'active': true });
            // => objects for ['barney']
        */

        return _.filter(obj, parms);
    },

    orderBy: function (obj, params, direction = 'asc') {

        /*
            var users = [
              { 'user': 'fred',   'age': 48 },
              { 'user': 'barney', 'age': 34 },
              { 'user': 'fred',   'age': 40 },
              { 'user': 'barney', 'age': 36 }
            ];

            _.orderBy(users, ['user', 'age'], ['asc', 'desc']);
        */

        return _.orderBy(obj, params, direction);
    },

    merge: function (obj1, obj2) {

        return _.merge(obj1, obj2);
    }
}

window.object = object
export default object


