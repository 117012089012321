/*
 ________      ________      ________          ________             ________       ___    ___  ___      _________
|\   __  \    |\   __  \    |\   ____\        |\   __  \           |\   __  \     |\  \  /  /||\  \    |\___   ___\
\ \  \|\ /_   \ \  \|\ /_   \ \  \___|        \ \  \|\  \  /\      \ \  \|\ /_    \ \  \/  / /\ \  \   \|___ \  \_|
 \ \   __  \   \ \   __  \   \ \  \            \ \__     \/  \      \ \   __  \    \ \    / /  \ \  \       \ \  \
  \ \  \|\  \   \ \  \|\  \   \ \  \____        \|_/  __     /|      \ \  \|\  \    \/  /  /    \ \  \____   \ \  \
   \ \_______\   \ \_______\   \ \_______\        /  /_|\   / /       \ \_______\ __/  / /       \ \_______\  \ \__\
    \|_______|    \|_______|    \|_______|       /_______   \/         \|_______||\___/ /         \|_______|   \|__|
                                                 |_______|\__\                   \|___|/
                                                         \|__|
*/

"use strict";

// Vendor
global.$ = global.jQuery = require('jquery')
import {gsap} from "gsap";
import slick from 'slick-carousel'
import Handlebars from 'handlebars'


// Helpers
import string from './helpers/string'
import object from './helpers/object'
import attr from './helpers/attributes'
import hbsHelpers from './helpers/hbs'


// Utils
//import routing from './routing/highway.js';
//import config from './utils/configuration/configuration.js';
//import forms from './utils/forms/uniform'
import general from './utils/general.js'


// Components
//import ___OLD_clients from './components/___ OLD _clients.js'


// Pages


// Global animation speed
window.speed = 3


var main = {

    initApp: function () {

    },

    initPage: function () {

        config.pageInit()
        routing.setBodyClass()
        routing.activeLinks()

        uniform.init()
    }
}

window.main = main
export default main

window.addEventListener('DOMContentLoaded', function (event) {

    // Start in Showpad
    main.initApp()
})
