var attr = {

    init: function () {
    },

    hasClass: function (element, classname) {

        if (!element.className) return

        if (element.className.split(' ').indexOf(classname) >= 0) return true;

    },

    parentHasClass: function (element, classname) {

        if (!element.className) return

        if (element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && attr.parentHasClass(element.parentNode, classname);
    }
}

window.attr = attr
export default attr


